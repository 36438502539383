<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-table-filter
          :items="rows"
          :fields="fields"
          :filter="filter"
          :filter-on="filterOn"
          :is-busy="false"
          @to-edit="toEdit"
          totalRows="1"
          title="Danh Sách Nhóm"
        >
        <template #rightAction>
          <b-button variant="success" :to="{ name: 'groups-create'}"><feather-icon icon="PlusIcon" />Thêm Nhóm</b-button>
        </template>
        </b-table-filter>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormInput, BCol, BRow, BFormGroup,
  BFormDatepicker, BButton, BFormCheckbox, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueSelect from 'vue-select'
import BTableFilter from '@core/components/datatable/BTableFilter.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  methods: {
    toEdit(data) {
      this.$router.push({name: 'groups-edit', params: { id: data.item.id}})
    }
  },
  components: {
    BFormCheckbox,
    VueSelect,
    BFormGroup,
    BCol,
    BRow,
    BTableFilter,
    BFormDatepicker,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    FeatherIcon
},
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'Tên Nhóm',
          key: 'name',
        },
        {
          label: 'Tên Nhóm',
          key: 'leader',
        },
        {
          label: '',
          key: 'act',
          thStyle: {
            'min-width': '10%'
          },
        }
      ],
      rows: [],
    }
  },
  created() {
    this.rows = [
      {
        name: 'Tên Nhóm',
        leader: 'Trưởng Nhóm',
        id: 1,
      },
    ]
  },
}
</script>
